<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">Jadwal</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <div class="row">
        <div class="col-sm-6">
          <SubjectSelectField
            label="Pelajaran"
            required
            v-model="input.subject_id"
            :error="errors.subject_id"
          />
        </div>
        <div class="col-sm-6">
          <TeacherSelectField
            class="mt-16 mt-sm-0"
            label="Guru"
            required
            v-model="input.teacher_id"
            :error="errors.teacher_id"
          />
        </div>
      </div>
      <SelectField
        class="mt-16"
        label="Hari"
        required
        v-model="input.day"
        :error="errors.day"
        :multiselect="{ options: options.day, placeholder: '' }"
      />
      <div class="row">
        <div class="col-sm-6">
          <DateField
            class="mt-16"
            label="Jam mulai"
            required
            v-model="input.time_start"
            :error="errors.time_start"
            :vue2Datepicker="{
              format: 'HH:mm',
              minuteStep: 10,
              type: 'time',
              valueType: 'format',
            }"
          />
        </div>
        <div class="col-sm-6">
          <DateField
            class="mt-16"
            label="Jam selesai"
            required
            v-model="input.time_end"
            :error="errors.time_end"
            :vue2Datepicker="{
              disabledTime: notBeforeTimeStart,
              format: 'HH:mm',
              minuteStep: 10,
              type: 'time',
              valueType: 'format',
            }"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import dayjs from '../../../bootstrap/dayjs';
import scheduleRepository from '../../../api/repositories/scheduleRepository';
import Role from '../../../store/models/Role';
import Schedule from '../../../store/models/Schedule';

import DateField from '../../form/DateField.vue';
import SelectField from '../../form/SelectField.vue';
import SubjectSelectField from '../../subject/SubjectSelectField.vue';
import TeacherSelectField from '../../teacher/TeacherSelectField.vue';

export default {
  components: {
    DateField,
    SelectField,
    SubjectSelectField,
    TeacherSelectField,
  },

  props: ['schedule'],

  data() {
    return { errors: {}, input: this.parseInput(), isSubmitting: false };
  },

  computed: {
    options() {
      return { day: Schedule.dayOptions };
    },

    teacherRole() {
      const role = Role.query().where('code', 'teacher').first();
      return role;
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;
        const input = this.prepareInput();

        if (this.input.id) {
          await scheduleRepository.update(input);
          this.$alert.success('Jadwal berhasil diubah');
        } else {
          await scheduleRepository.store(input);
          this.$alert.success('Jadwal berhasil dibuat', {
            onOK: () =>
              this.$router.push(
                `/admin/kelas/${this.$route.params.grade_id}/jadwal`
              ),
          });
        }

        this.$store.commit('schedules/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    prepareInput() {
      return { grade_id: this.$route.params.grade_id, ...this.input };
    },

    parseInput() {
      const input = this.schedule ? this.schedule.$toJson() : {};
      return input;
    },

    notBeforeTimeStart(date) {
      if (!this.input.time_start) return false;
      return dayjs(date).format('HH:mm') <= this.input.time_start;
    },
  },

  watch: {
    'input.time_start'(time_start) {
      if (this.input.time_end && time_start > this.input.time_end) {
        this.input.time_end = null;
      }
    },

    schedule() {
      this.input = this.parseInput();
    },
  },

  beforeMount() {
    this.$store.dispatch('roles/maybeFetch');
  },
};
</script>
