<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <ScheduleForm :schedule="schedule" />
  </main>
</template>

<script>
import Grade from '../../../../store/models/Grade';
import Schedule from '../../../../store/models/Schedule';

import ScheduleForm from '../../../../components/admin/schedule/ScheduleForm.vue';

export default {
  components: { ScheduleForm },

  computed: {
    grade() {
      return Grade.find(this.$route.params.grade_id);
    },

    schedule() {
      return Schedule.query().withAll().find(this.$route.params.schedule_id);
    },
  },

  metaInfo() {
    return { title: this.schedule && this.schedule.subject.name };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.schedule_id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/kelas', label: 'Kelas' },
      { path: this.grade.editRoutePath, label: this.grade.name },
      { path: this.grade.scheduleRoutePath, label: 'Jadwal' },
    ]);
  },
};
</script>
